export default class DonationForm {

  constructor(selector) {
    this.form = document.querySelector(selector);
    if (!this.form) { return }
    this.submitButton = this.form.querySelector('button[type="submit"]');

    this.submitButton.addEventListener('click', (event) => {
      console.log(event.target);
    });


    this.fv = FormValidation.formValidation(
      this.form,
      {
        // Set the default locale
        locale: 'de_DE',
        localization: FormValidation.locales.de_DE,

        fields: {
          amount: {
            validators: {
              notEmpty: {
                message: 'Bitte geben Sie Ihren gewünschten Spendenbetrag an.',
              },
            }
          },

          interval: {
            validators: {
              notEmpty: {
                message: 'Bitte geben Sie Ihr gewünschtes Spendenintervall an.',
              },
            }
          },

          firstname: {
            validators: {
              notEmpty: {
                message: 'Bitte geben Sie Ihren Vornamen ein.',
              },
            }
          },
          lastname: {
            validators: {
              notEmpty: {
                message: 'Bitte geben Sie Ihren Nachnamen ein.',
              },
            }
          },

          email: {
            validators: {
              emailAddress: {
                message: 'Bitte geben Sie eine gültige E-Mail Adresse ein.',
              },
            }
          },
          iban: {
            validators: {
              iban: {
                message: 'Bitte geben Sie eine gültige IBAN ohne Leerzeichen ein.',
                sepa: true,
              },
              notEmpty: {
                message: 'Bitte geben Sie Ihre IBAN ein.',
              },
            }
          },
          bic: {
            validators: {
              bic: {
                message: 'Bitte geben Sie eine gültige BIC ein.',
              },
              notEmpty: {
                message: 'Bitte geben Sie die BIC Ihres Kreditinstituts ein.',
              },
            }
          },
          bank: {
            validators: {
              notEmpty: {
                message: 'Bitte geben Sie Ihr Kreditinsitut an.',
              },
            }
          },

          direct_debit: {
            validators: {
              notEmpty: {
                message: 'Bitte bestätigen Sie die Ermächtigung zum Bankeinzug.',
              },
            }
          },

          terms: {
            validators: {
              notEmpty: {
                message: 'Bitte bestätigen Sie Ihre Einverständniserklärung zur Verarbeitung der von Ihnen eingegeben Daten.',
              },
            }
          },
        },
        plugins: {
          trigger: new FormValidation.plugins.Trigger({
            event: 'input change',
          }),
          aria: new FormValidation.plugins.Aria(),
          // submitButton: new FormValidation.plugins.SubmitButton(),
          message: new FormValidation.plugins.Message({
            clazz: 'text-orange-700 my-2',
            container: (field, element) => {
              return element.closest('.form--item');
            }
          }),
          // fieldStatus: new FormValidation.plugins.FieldStatus({
          //   onStatusChanged: (areFieldsValid) => {
          //     areFieldsValid
          //       // Enable the submit button
          //       // so user has a chance to submit the form again
          //       ? this.submitButton.removeAttribute('disabled')
          //       // Disable the submit button
          //       : this.submitButton.setAttribute('disabled', 'disabled');
          //   }
          // }),
        }
      }
    );

    // this.submitButton.setAttribute('disabled', true);

    // this.fv.on('core.form.invalid', () => {
    //   // Disable the submit button
    //   this.submitButton.setAttribute('disabled', true);
    // });
    // this.fv.on('core.form.valid', () => {
    //   // Enable the submit button
    //   this.submitButton.removeAttribute('disabled');
    // });

  }

}