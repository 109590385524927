export default class NavMobile {

  constructor() {
    this.html = document.querySelector('html');
    this.body = document.querySelector('body');

    if (this.body.dataset.navMobileInitialized) {
      return;
    }
    this.body.dataset.navMobileInitialized = true;

    this.triggerButton = document.querySelector('.navigation--trigger');
    this.navWrapper = document.querySelector('.header--navigation-wrapper');
    this.nav = document.querySelector('.header--navigation');
    this.active = false;
    this.level1Items = document.querySelectorAll('.navigation--header-item.level-1');

    if (this.nav && this.navWrapper && this.triggerButton) {
      this.body.addEventListener('click', (event) => {
        if (this.active) {
          setTimeout(() => {
            this.html.classList.remove('navigation__active');
            this.html.classList.remove('overflow-hidden');
            this.navWrapper.classList.replace('opacity-100', 'opacity-0');
            this.navWrapper.classList.replace('visible', 'invisible');
          }, event.target.classList.contains('navigation--header-link') ? 100 : 0)
          setTimeout(() => {
            this.nav.scrollTop = 0;
          }, 400);
          this.active = false;
        }
        else {
          if (event.target == this.triggerButton) {
            this.active = true;
            this.html.classList.add('navigation__active');
            this.html.classList.add('overflow-hidden');
            this.navWrapper.classList.replace('opacity-0', 'opacity-100');
            this.navWrapper.classList.replace('invisible', 'visible');
          }
        }

        this.triggerButton.setAttribute('aria-pressed', this.active);
        this.triggerButton.setAttribute('aria-label', this.active ? 'Navigation ausblenden' : 'Navigation einblenden');
        this.navWrapper.setAttribute('aria-hidden', !this.active);
      });
    }

    // mobile toggle level 2
    this.level1Items.forEach(item => {
      let trigger = item.querySelector('.navigation--mobile-toggle');

      if (trigger) {
        let level2 = item.querySelector('ul.navigation--level-2');
        trigger.addEventListener('click', (event) => {
          event.preventDefault();
          event.stopPropagation();

          level2.classList.toggle('hidden');
          trigger.classList.toggle('is-active');
        });
      }
    });
  }

}