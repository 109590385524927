class FvkkAmount extends HTMLElement {

  constructor() {
    super();

    this.numberInput = this.querySelector('input[name="amount"]');
    this.slider = this.querySelector('input[type="range"]');

    this.numberInput.addEventListener('input', (event) => {
      if (isNaN(parseInt(this.numberInput.value))) {
        this.numberInput.value = '';
      }
      else {
        this.numberInput.value = Math.min(Math.max(parseInt(this.numberInput.value), this.min), this.max);
        this.slider.value = this.numberInput.value;
      }
    });

    this.slider.addEventListener('input', (event) => {
      this.numberInput.value = this.slider.value;
      this.numberInput.dispatchEvent(new Event('change'));
    });
  }

  get default() {
    return this.getAttribute('default');
  }

  set default(val) {
    if (val) {
      this.setAttribute('default', val);
    }
    else {
      this.removeAttribute('default');
    }
  }

  get min() {
    return this.getAttribute('min');
  }

  set min(val) {
    if (val) {
      this.setAttribute('min', val);
    }
    else {
      this.removeAttribute('min');
    }
  }

  get max() {
    return this.getAttribute('max');
  }

  set max(val) {
    if (val) {
      this.setAttribute('max', val);
    }
    else {
      this.removeAttribute('max');
    }
  }

}


// Define the new web component
if ('customElements' in window) {
  customElements.define('fvkk-amount', FvkkAmount);
}