export default class Scrolling {

  constructor(selector) {
    this.body = document.querySelector('body');

    document.addEventListener('scroll', (event) => {
      this.body.classList.toggle('scrolled' , window.scrollY > (window.innerWidth < 760 ? 100 : 180));
    });

    setTimeout(() => {
      document.dispatchEvent(new Event('scroll'));
    }, 10);
  }

}