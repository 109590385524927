export default class Search {

  constructor(selector) {

    const wrapper = document.querySelector(selector);
    if (!wrapper) {
      return;
    }
    if (wrapper.dataset['searchInitialized'] == 'true') { return }
    wrapper.dataset['searchInitialized'] = 'true';

    const trigger = wrapper.querySelector('.header--search-trigger')
    const form = wrapper.querySelector('.header--search-form')

    if (form && trigger){
      form.addEventListener('click', (event) => {
        event.stopPropagation();
      });
      trigger.addEventListener('click', (event) => {
        event.preventDefault();
        wrapper.classList.toggle('header--search__active');
      });
    }



  }
}