export default class NavDesktop {

  constructor() {
    // this.html = document.querySelector('html');
    this.body = document.querySelector('body');

    if (this.body.dataset.navDesktopInitialized) {
      return;
    }
    this.body.dataset.navDesktopInitialized = true;
    this.navItemsOverlay = document.querySelectorAll('.header--navigation-item-overlay');

    this.navItemsOverlay.forEach((navItemOverlay, index) => {
      if (navItemOverlay) {
        navItemOverlay.addEventListener('mouseenter', (event) => {
          if (window.innerWidth > 760) {
            this.body.classList.add('navigation__active');
          }
        });
        navItemOverlay.addEventListener('mouseleave', (event) => {
          this.body.classList.remove('navigation__active');
        });
      }
    });
  }

}