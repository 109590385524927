export default class YoutubeEmbed {

  constructor(selector) {
    this.wrappers = document.querySelectorAll(selector);
    if (!this.wrappers) {
      return;
    }

    for (let i = 0; i < this.wrappers.length; ++i) {
      const wrapper = this.wrappers[i];
      const iframe = wrapper.querySelector('.youtube--embed-iframe')

      if (iframe) {
        const notice = document.createElement('div');
        notice.classList.add('youtube--embed-notice', 'editor', 'text-lg', 'md:text-xl');
        notice.innerHTML = '<h3>YouTube-Video</h3><p>Wenn Sie dieses Video laden wird eine Verbindung zu den Servern von YouTube aufgebaut und evtl. personenbezogene Daten übertragen und Cookies gesetzt.</p><p>Mehr hierzu erfahren Sie in unserer <a href="/datenschutz" target="_blank">Datenschutzerklärung</a>.</p>';
        const trigger = document.createElement('button');
        trigger.classList.add('button', 'button-blue', 'mt-4', 'md:mt-6');
        trigger.innerHTML = '<span>Video laden</span>';

        trigger.addEventListener('click', (event) => {
          event.preventDefault();

          notice.remove();
          trigger.remove();

          iframe.setAttribute('src', iframe.dataset.src);

          wrapper.classList.remove('showing-notice')
        });

        notice.appendChild(trigger);
        wrapper.appendChild(notice);
        wrapper.classList.add('showing-notice');
      }
    }
  }

}