import FvkkAmount from "./components/fvkk-amount";

import NavMobile from "./components/nav-mobile";
import NavDesktop from "./components/nav-desktop";
import Scrolling from "./components/scrolling";
import Jumpmenu from "./components/jumpmenu";
import FvkkAccordion from "./components/accordion";
import Newslist from "./components/newslist";
import YoutubeEmbed from "./components/youtube-embed";
import Search from "./components/search";
import DonationForm from "./components/donation-form";


new Jumpmenu();
new Scrolling();
new NavMobile();
new NavDesktop();
new FvkkAccordion('.js--accordion');
new Newslist();
new YoutubeEmbed('.js--youtube-embed');
new Search('.js--search');
new DonationForm('.js--donation-form');

document.documentElement.classList.replace('no-js', 'js');