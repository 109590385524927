export default class Newslist {

  constructor() {
    this.wrappers = document.querySelectorAll('.js--newslist');

    if (!this.wrappers.length) { return }

    this.wrappers.forEach((wrapper, index) => {
      let trigger = wrapper.querySelector('.newslist-trigger');
      let container = wrapper.querySelector('.newslist-container');

      if (trigger && container) {
        trigger.addEventListener('click', (event) => {
          if (trigger.disabled || trigger.hidden) { return }
          event.preventDefault();
          event.stopPropagation();
          const url = window.location.origin + "/newslist/" + trigger.dataset.category + "/" + trigger.dataset.limit + "/" + trigger.dataset.page
          this.fetchProjects(url, trigger, container);
        });
      }
    });
  }

  fetchProjects = async (url, trigger, container) => {
    try {
      trigger.disabled = true;
        const response = await fetch(url);
        const { html, more, next_page } = await response.json();

        setTimeout(() => {
          trigger.hidden = !more;
          if (next_page && more) {
            trigger.dataset.page = next_page;
          }
          trigger.disabled = false;
          container.insertAdjacentHTML('beforeend', html);
        }, 100);
    } catch (error) {
      console.log('Fetch error: ', error);
      trigger.disabled = false;
    }
  }

}