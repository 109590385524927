import animateScrollTo from 'animated-scroll-to';

export default class Jumpmenu {

  constructor(selector) {
    this.prepare();
  }


  prepare() {
    return;
    // for (let i = 0; i < this.links.length; ++i) {

    //   if (!this.links[i].dataset.jumpmenuinitialized) {
    //     let target = document.querySelector(this.links[i].hash + '-target');
    //     this.links[i].addEventListener('click', (event) => {
    //       event.preventDefault();
    //       // if (target && this.body.classList.contains('is-home')) {
    //       if (target) {
    //         this.aimToTarget(this.links[i], target);
    //       }
    //     });
    //   }
    //   this.links[i].dataset.jumpmenuinitialized = true;
    // }


    if (window.location.hash) {
      // let _link = document.querySelector('.navigation--header-link[href="' + window.location.hash + '"]');
      let _target = document.querySelector(window.location.hash);
      if (_target) {
        // this.aimToTarget(_link, _target);
        setTimeout(() => {
          this.aimToTarget(_target);
        }, 1);
      }
    }

    window.addEventListener('hashchange', (event) => {
      event.preventDefault();
      let _target = document.querySelector(window.location.hash);
      if (_target) {
        // this.aimToTarget(_link, _target);
        this.aimToTarget(_target);
      }
    });
    // else {
    //   // window.scrollTo(0, 0);
    // }

  }


  // aimToTarget(link, target) {
  aimToTarget(target) {
    if (target) {
      setTimeout(() => {
        this.scrollTo(target);
      }, 100);
    }
  }


  activateLink(link) {
    for (let i = 0; i < this.links.length; ++i) {
      this.links[i].classList.remove('is-active');
    }
    if (link) {
      link.classList.add('is-active');
    }
  }


  scrollTo(target) {
    // let _offset = window.innerWidth > 1280 ? -200 : -110;
    const _options = {
      speed: 500,
      minDuration: 250,
      maxDuration: 750,
      // verticalOffset: _offset
    };

    animateScrollTo(target, _options)
      .then((hasScrolledToPosition) => {
        // // remove hash
        // history.pushState("", document.title, window.location.pathname + window.location.search);
      });
    }

}